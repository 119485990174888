import invoiceHandler from "../../services/Invoice";

export default {
    async loadInvoices({commit, dispatch}, payload) {
        const {data} = await invoiceHandler.all(payload);

        commit('SET_INVOICES', data);
    },
    async storeInvoice({commit, dispatch}, payload) {
        return invoiceHandler.store(payload);
    },
    async getByInvoiceId({commit, dispatch}, payload) {
        return invoiceHandler.get(payload);
    },
    async updateManualPayment({commit, dispatch}, payload) {
        return invoiceHandler.updateManualPayment(payload);
    },
    async updateInvoice({commit, dispatch}, payload) {
        return invoiceHandler.update(payload);
    },
    async destroyInvoice({commit, dispatch}, payload) {
        return invoiceHandler.remove(payload);
    },
    async destroyInvoicePermanent({commit, dispatch}, payload) {
        return invoiceHandler.destroy(payload);
    },
    async restoreInvoice({commit, dispatch}, payload) {
        return invoiceHandler.restore(payload);
    },
    async resetInvoiceHandler({commit, dispatch}, payload) {
        return invoiceHandler.resetInvoice(payload);
    },
    async resendMailHandler({commit, dispatch}, payload) {
        return invoiceHandler.paymentMail(payload);
    },
    async sendReminderMail({commit, dispatch}, payload) {
        return invoiceHandler.reminderMail(payload);
    },
    async sendAfterReminderMail({commit, dispatch}, payload) {
        return invoiceHandler.afterReminderMail(payload);
    },
    async refundInvoice({commit, dispatch}, payload) {
        return invoiceHandler.refund(payload);
    },
    async invoiceMarkAsRead({commit, dispatch}, payload) {
        return invoiceHandler.invoiceMarkAsRead(payload);
    },
    async getInvoiceDescriptionByEmail({commit, dispatch}, payload) {
        return invoiceHandler.getInvoiceDescriptionByEmail(payload);
    },
    async republishedEmail({commit, dispatch}, payload) {
        return invoiceHandler.republishedEmail(payload);
    }
}
