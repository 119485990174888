<template>
  <div>
    <v-card :light="!$vuetify.theme.dark" class="ma-2" color="#fafafa" elevation="0">
      <v-card-text>
        <router-link to="/">
          <v-img
              :src="logo"
              max-height="50"
          />
        </router-link>
      </v-card-text>
    </v-card>
    <v-divider/>
    <v-list rounded>
      <template v-for="item in menus">
        <v-list-group
            v-if="item.subMenuItems && haveAccessMenu(item.title)"
            :prepend-icon="item.icon"
            color="#fafafa"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
              v-if="haveAccessMenu(subMenu.title)"
              v-for="(subMenu, i) in item.subMenuItems"
              :key="i"
              :to="subMenu.path"
              color="#fafafa"
              router
              link
              exact
          >
            <v-list-item-icon>
              <v-icon v-text="subMenu.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="subMenu.title"></v-list-item-title>
          </v-list-item>
        </v-list-group>
        <template  v-else>
          <v-list-item
              :to="item.path"
              color="#fafafa"
              router
              link
              exact
              v-if="haveAccessMenu(item.title)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

      </template>
    </v-list>
  </div>
</template>

<script>
import {mapState} from "vuex";
import pTable from "../../json/pTable.json";
export default {
  name: "AppSidebar",
  data() {
    return {
      menus: [
        {title: 'Dashboard', icon: 'mdi-view-dashboard', path: '/'},
        {title: 'Summary Reports', icon: 'mdi-graph', path: '/summary-reports'},
        {title: 'Reports', icon: 'mdi-equal-box', path: '/reports'},
        {title: 'Orders', icon: 'mdi-briefcase', path: '/orders'},
        {
          title: 'Counters',
          icon: 'mdi-briefcase',
          subMenuItems: [
            {title: 'Create New Order', icon: 'mdi-cart-outline', path: '/sale-counter'},
            {title: 'Reseller Order List', icon: 'mdi-view-list', path: '/sale-counters'},
          ]
        },
        {
          title: 'Invoices',
          icon: 'mdi-cart-outline',
          subMenuItems: [
            {title: 'Create New Invoice', icon: 'mdi-cart-outline', path: '/invoice'},
            {title: 'Reseller Invoice List', icon: 'mdi-view-list', path: '/invoices'},
          ]
        },
        {title: 'Broken Links', icon: 'mdi-vector-link', path: '/broken-links'},
        {
          title: 'Users',
          icon: 'mdi-account',
          subMenuItems: [
            {title: 'Manual Credits', icon: 'mdi-bank', path: '/payments'},
            {title: 'Users', icon: 'mdi-account-multiple', path: '/users'},
            {title: 'Admin Users', icon: 'mdi-handshake', path: '/admins'}
          ]
        },
        {title: 'Clients', icon: 'mdi-account-group', path: '/clients'},
        {title: 'Mail Box', icon: 'mdi-mailbox', path: '/mail-box'},
        /*{
          title: 'Clients',
          icon: 'mdi-account-group',
          subMenuItems: [
            {title: 'Fix Clients', icon: 'mdi-account-group', path: '/fix-clients'},
            {title: 'Special Clients', icon: 'mdi-shield-star', path: '/special-clients'},
            {title: 'More Special Clients', icon: 'mdi-star-three-points', path: '/more-special-clients'},
            {title: 'Agency Clients', icon: 'mdi-alpha-a-circle', path: '/agency-clients'},
          ]
        },
        {
          title: 'Mail Box',
          icon: 'mdi-email-box',
          subMenuItems: [
            {title: 'Mail Box', icon: 'mdi-mailbox', path: '/mail-box'},
            {title: 'Mail Box Fix Client', icon: 'mdi-card-account-details-star', path: '/mail-box-fix-client'},
            {title: 'Mail Box Agency Client', icon: 'mdi-alpha-a-circle', path: '/mail-box-agency-client'},
          ]
        },*/
        {
          title: 'Settings',
          icon: 'mdi-card-bulleted-settings',
          subMenuItems: [
            {title: 'Sites', icon: 'mdi-sitemap', path: '/sites'},
            {title: 'Methods', icon: 'mdi-cash-register', path: '/methods'},
            {title: 'Extra Facilities', icon: 'mdi-postage-stamp', path: '/extra-facilities'},
            {title: 'Niche Categories', icon: 'mdi-code-tags', path: '/niche-categories'},
            {title: 'Mail Templates', icon: 'mdi-grid', path: '/mail-templates'},
            {title: 'Links', icon: 'mdi-link', path: '/allowed-links'},
            {title: 'Task Schedulers', icon: 'mdi-folder-network', path: '/task-scheduler'},
            {title: 'Logs', icon: 'mdi-database-settings', path: '/logs'},
            {title: 'Site Owners', icon: 'mdi-minecraft', path: '/site-owners'},
            {title: 'WP Access', icon: 'mdi-lock', path: '/wp-access-control'},
            {title: 'Site Ownership', icon: 'mdi-lock-reset', path: '/site-ownerships'}
          ]
        },
        {
          title: 'Optional',
          icon: 'mdi-medical-bag',
          subMenuItems: [
            {title: 'Link Search', icon: 'mdi-search-web', path: '/link-search'},
            {title: 'Order Delivery', icon: 'mdi-truck-delivery', path: '/order-delivery'},
            {title: 'Background Jobs', icon: 'mdi-message-settings', path: '/background-jobs'},
            {title: 'Shanto IT Invoices', icon: 'mdi-equal-box', path: '/shanto-it-invoices'},
            {title: 'Alfa Coins Currency', icon: 'mdi-currency-btc', path: '/alfa-coins-currency'},
            {title: 'Paypal', icon: 'mdi-checkerboard', path: '/paypal'},
            {title: 'Google Index', icon: 'mdi-google-analytics', path: '/google-index'},
          ]
        },
      ],
      logo: require('../../assets/img/logo.png'),
    }
  },
  props: {
    miniVariant: {
      type: Boolean,
      default: () => false
    }
  },
  computed:{
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    haveAccessMenu(title) {
      switch (title.toLowerCase()){
        case 'reports':
          return this.user && (this.user.role_id === 1 || this.setPermission(this.user.email, 'report.reportNav'));
        case 'background jobs':
          return !(this.user && this.user.role_id !== 1)
        case 'mail box':
          return this.user && (this.setPermission(this.user.email, 'settings.mailBox'))
        case 'task schedulers':
          return !(this.user && this.user.role_id !== 1)
        case 'site owners':
          return this.user && (this.user.role_id === 1 || this.setPermission(this.user.email, 'settings.siteOwners'))
        case 'wp access':
          return this.user && (this.user.role_id === 1 || this.setPermission(this.user.email, 'settings.wp-access'))
        case 'site ownership':
          return this.user && (this.user.role_id === 1 || this.setPermission(this.user.email, 'settings.siteOwnerships'))
        case 'summary reports':
          return !(this.user && this.user.role_id !== 1)
        default:
          return true;
      }
    },
    setPermission(email, column) {
      let columnSplit= column.split(".");
      let pData = pTable;

      columnSplit.forEach((item) => {
        pData =  pData[item];
      });

      return pData.find(item => item === email);
    }
  }
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  .company-logo-panel {
    width: 100%;
    text-align: center;
    transition: transform .3s ease-in-out, width .3s ease-in-out;

    .image {
      img {
        width: 100%;
        max-width: 100px;
        height: auto;
      }
    }

    .company-name {
      padding: 10px 5px 0px;
      color: #616161 !important;
    }
  }
}
</style>
