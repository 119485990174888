import AppHeader from "../layouts/AppHeader";
import AppFooter from "../layouts/AppFooter";

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        components: {
            header: AppHeader,
            default: () => import('../views/Dashboard'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/summary-reports',
        name: 'SummaryReports',
        components: {
            header: AppHeader,
            default: () => import('../views/SummaryReports'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: {guestOnly: true}
    },
    {
        path: '/verify',
        name: 'Verify',
        component: () => import('../views/Verify'),
        meta: {guestOnly: true}
    },
    {
        path: '/extra-facilities',
        name: 'ExtraFacilities',
        components: {
            header: AppHeader,
            default: () => import('../views/ExtraFacilities'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/niche-categories',
        name: 'NicheCategories',
        components: {
            header: AppHeader,
            default: () => import('../views/NicheCategories.vue'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/sites',
        name: 'Sites',
        components: {
            header: AppHeader,
            default: () => import('../views/Sites.vue'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/orders',
        name: 'Orders',
        components: {
            header: AppHeader,
            default: () => import('../views/Orders.vue'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/users',
        name: 'Users',
        components: {
            header: AppHeader,
            default: () => import('../views/Users.vue'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/profile',
        name: 'Profile',
        components: {
            header: AppHeader,
            default: () => import('../views/Profile.vue'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/methods',
        name: 'Methods',
        components: {
            header: AppHeader,
            default: () => import('../views/Methods'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/payments',
        name: 'Payments',
        components: {
            header: AppHeader,
            default: () => import('../views/ManualCredits'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports',
        name: 'Reports',
        components: {
            header: AppHeader,
            default: () => import('../views/Reports'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/invoice',
        name: 'Invoice',
        components: {
            header: AppHeader,
            default: () => import('../views/invoices/Invoice'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/invoices',
        name: 'Invoices',
        components: {
            header: AppHeader,
            default: () => import('../views/invoices/Invoices'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/invoice-migration',
        name: 'InvoicesMigration',
        components: {
            header: AppHeader,
            default: () => import('../views/InvoiceMigration'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/edit-invoice/:id',
        name: 'EditInvoice',
        components: {
            header: AppHeader,
            default: () => import('../views/invoices/EditInvoice'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/live-link-replacement/:id',
        name: 'LiveLinkReplacement',
        components: {
            header: AppHeader,
            default: () => import('../views/LiveLinkReplacement'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/admins',
        name: 'Admins',
        components: {
            header: AppHeader,
            default: () => import('../views/Admins'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/mail-templates',
        name: 'MailTemplates',
        components: {
            header: AppHeader,
            default: () => import('../views/MailTemplates'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/sale-counters',
        name: 'SaleCounters',
        components: {
            header: AppHeader,
            default: () => import('../views/sales-counters/SalesCounters'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/sale-counter',
        name: 'SaleCounter',
        components: {
            header: AppHeader,
            default: () => import('../views/sales-counters/SaleCounter'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/edit-sale-counter/:id',
        name: 'EditSaleCounter',
        components: {
            header: AppHeader,
            default: () => import('../views/sales-counters/EditSaleCounter'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/counters',
        name: 'CounterReport',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/CounterReport'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/invoices',
        name: 'InvoicesReport',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/InvoiceReport'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/sites',
        name: 'SitesReport',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/SiteReport'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/site-summary',
        name: 'siteSummary',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/SiteSummaryReport'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/fix-clients',
        name: 'FixClients',
        components: {
            header: AppHeader,
            default: () => import('../views/FixClients'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/broken-links',
        name: 'BrokenLinks',
        components: {
            header: AppHeader,
            default: () => import('../views/BrokenLinks'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/allowed-links',
        name: 'AllowedLinks',
        components: {
            header: AppHeader,
            default: () => import('../views/settings/links'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/sale-counter-details/:id',
        name: 'SaleCounterDetails',
        components: {
            header: AppHeader,
            default: () => import('../views/sales-counters/SaleDetails'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/background-jobs',
        name: 'BackgroundJobs',
        components: {
            header: AppHeader,
            default: () => import('../views/BackgroundJobs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/invoice-mail-details/:id',
        name: 'InvoiceMailDetails',
        components: {
            header: AppHeader,
            default: () => import('../views/invoices/MailDetails'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/invoice/invoice-by-mail',
        name: 'InvoiceByMail',
        components: {
            header: AppHeader,
            default: () => import('../views/invoices/InvoiceByMail'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/special-clients',
        name: 'SpecialClient',
        components: {
            header: AppHeader,
            default: () => import('../views/settings/SpecialClients'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/agency-clients',
        name: 'AgencyClient',
        components: {
            header: AppHeader,
            default: () => import('../views/settings/AgencyClients'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/mail-box',
        name: 'MailBox',
        components: {
            header: AppHeader,
            default: () => import('../views/MailBox'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/logs',
        name: 'Logs',
        components: {
            header: AppHeader,
            default: () => import('../views/Logs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/task-scheduler',
        name: 'TaskScheduler',
        components: {
            header: AppHeader,
            default: () => import('../views/TaskScheduler'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/wp-access-control',
        name: 'WPAccessControl',
        components: {
            header: AppHeader,
            default: () => import('../views/WPAccess'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/site-logs',
        name: 'SiteLogs',
        components: {
            header: AppHeader,
            default: () => import('../views/logs/SiteLogs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/newsletter-logs',
        name: 'NewsletterLogs',
        components: {
            header: AppHeader,
            default: () => import('../views/logs/NewsLetterLogs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/queued-job-logs',
        name: 'QueuedJobLogs',
        components: {
            header: AppHeader,
            default: () => import('../views/logs/QueuedJobLogs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/delivery-email-logs',
        name: 'DeliveryEmailLogs',
        components: {
            header: AppHeader,
            default: () => import('../views/logs/DeliveryEmailLogs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/failed-queued-logs',
        name: 'FailedQueuedLogs',
        components: {
            header: AppHeader,
            default: () => import('../views/logs/FailedQueuedLogs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/duplicated-links-logs',
        name: 'DuplicatedLinksLogs',
        components: {
            header: AppHeader,
            default: () => import('../views/logs/DuplicatedLinksLogs'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/shanto-it-invoices',
        name: 'ShantoITInvoices',
        components: {
            header: AppHeader,
            default: () => import('../views/ShantoITInvoices'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/order-delivery',
        name: 'OrderDelivery',
        components: {
            header: AppHeader,
            default: () => import('../views/OrderDelivery'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/mail-box-fix-client',
        name: 'MailBoxFixClient',
        components: {
            header: AppHeader,
            default: () => import('../views/MailBoxFixClient'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/mail-box-agency-client',
        name: 'MailBoxAgencyClient',
        components: {
            header: AppHeader,
            default: () => import('../views/MailBoxAgencyClient'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/client-summary',
        name: 'ClientSummary',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/ClientSummaryReport'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/sites-statement',
        name: 'SitesStatement',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/SitesStatement'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/profile/google-sheet-sync',
        name: 'GoogleSheetSync',
        components: {
            header: AppHeader,
            default: () => import('../views/GoogleSheetSync'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/site-owners',
        name: 'SiteOwners',
        components: {
            header: AppHeader,
            default: () => import('../views/SiteOwners'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/site-ownerships',
        name: 'SiteOwnerships',
        components: {
            header: AppHeader,
            default: () => import('../views/SiteOwnership'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/link-search',
        name: 'LinkSearch',
        components: {
            header: AppHeader,
            default: () => import('../views/LinkSearch'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/google-index',
        name: 'GoogleIndex',
        components: {
            header: AppHeader,
            default: () => import('../views/GoogleAnalytics'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/more-special-clients',
        name: 'MoreSpecialClient',
        components: {
            header: AppHeader,
            default: () => import('../views/settings/MoreSpecialClients'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/reports/statements',
        name: 'StatementReports',
        components: {
            header: AppHeader,
            default: () => import('../views/reports/StatementReport'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/change-admin-password',
        name: 'ChangeAdminPassword',
        components: {
            header: AppHeader,
            default: () => import('../views/change-admin-password'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/alfa-coins-currency',
        name: 'AlfaCoinsCurrency',
        components: {
            header: AppHeader,
            default: () => import('../views/Cryptocurrency'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/paypal',
        name: 'Paypal',
        components: {
            header: AppHeader,
            default: () => import('../views/Paypal'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    },
    {
        path: '/clients',
        name: 'Clients',
        components: {
            header: AppHeader,
            default: () => import('../views/ClientsPage'),
            footer: AppFooter
        },
        meta: {authOnly: true}
    }
]

export default routes;
