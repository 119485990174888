import duplicatedLinksLogsHandler from "../../services/DuplicatedLinksLogs";

export default {
    async load({commit, dispatch}, payload) {
        try {
            const {data} = await duplicatedLinksLogsHandler.all(payload);

            commit('SET_DUPLICATED_LINKS_LOGS', data);
        } catch (error) {
            commit('SET_DUPLICATED_LINKS_LOGS', []);
        }
    },
    async get({commit, dispatch}, payload) {
        return duplicatedLinksLogsHandler.get(payload);
    },
    async destroy({commit, dispatch}, payload) {
        return duplicatedLinksLogsHandler.destroy(payload);
    },
    async clear({commit, dispatch}, payload) {
        return duplicatedLinksLogsHandler.clear(payload);
    },
    async markedMessageAsRead({commit, dispatch}, payload) {
        return duplicatedLinksLogsHandler.markedMessageAsRead(payload);
    }
}
