import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import user from '../services/User'
import extraFacility from "./extra-facility";
import nicheCategory from "./niche-category";
import method from "./method";
import site from "./site";
import order from "./order";
import credit from "./credit";
import invoice from "./invoice";
import mailTemplate from "./mail-template";
import report from "./report";
import salesCounter from "./sales-counter";
import fixClient from "./fix-client";
import brokenLink from "./broken-link";
import links from "./links";
import mailBox from "./mail-box";
import newsLetter from "./news-letter";
import taskScheduler from "./task-scheduler";
import wpAccess from "./wp-access";
import siteLog from "./site-logs";
import newsletterLog from "./newsletter-logs";
import queuedJobLogs from "./queued-job-logs";
import failedQueuedLogs from "./failed-queued-logs";
import shantoitInvoice from "./shantoit-invoice";
import orderDeliveryEmail from "./order-delivery-email";
import deliveryEmailLog from "./delivery-email-logs";
import clientSummary from "./client-summary";
import googleSheet from "./google-sheet-sync";
import siteOwner from "./site-owner";
import siteOwnership from "./site-ownership";
import statement from "./statement";
import cryptocurrency from "./cryptocurrency";
import sitesStatement from "./sites-statement";
import client from "./client";
import staff from "./staff";
import duplicatedLinksLog from "./duplicated-links-log";



export default new Vuex.Store({
    modules: {
        extraFacility,
        nicheCategory,
        site,
        order,
        method,
        credit,
        invoice,
        mailTemplate,
        report,
        salesCounter,
        fixClient,
        brokenLink,
        links,
        mailBox,
        newsLetter,
        taskScheduler,
        wpAccess,
        siteLog,
        newsletterLog,
        queuedJobLogs,
        failedQueuedLogs,
        shantoitInvoice,
        orderDeliveryEmail,
        deliveryEmailLog,
        clientSummary,
        googleSheet,
        siteOwner,
        siteOwnership,
        statement,
        cryptocurrency,
        sitesStatement,
        client,
        staff,
        duplicatedLinksLog
    },
    state: {
        snackBar: {},
        user: null,
        showLoading: false,
        users: [],
        adminUsers: [],
        allAdminUsers: [],
        dynamicWidgets: []
    },
    mutations: {
        SET_SNACKBAR: function (state, payload) {
            state.snackBar = payload
        },
        SET_USER: function (state, payload) {
            state.user = payload
        },
        SET_LOADING: function (state, payload) {
            state.showLoading = payload
        },
        SET_USERS: function (state, payload) {
            state.users = payload
        },
        SET_ADMIN_USERS: function (state, payload) {
            state.adminUsers = payload
        },
        SET_ALL_ADMIN_USERS: function (state, payload) {
            state.allAdminUsers = payload
        },
        SET_DYNAMIC_WIDGETS: function (state, payload) {
            state.dynamicWidgets = payload
        }
    },
    actions: {
        async loadUsers({commit, dispatch}, payload) {
            const {data} = await user.getUsers(payload);

            commit('SET_USERS', data);
        },
        async loadAdminUsers({commit, dispatch}, payload) {
            const {data} = await user.getAdmins(payload);

            commit('SET_ADMIN_USERS', data);
        },
        async loadAllAdminUsers({commit, dispatch}, payload) {
            const {data} = await user.getAllAdmins(payload);

            commit('SET_ALL_ADMIN_USERS', data);
        },
        async register({commit, dispatch}, payload) {
            return user.register(payload);
        },
        async updateAdmin({commit, dispatch}, payload) {
            return user.update(payload);
        },
        async updateStatus({commit, dispatch}, payload) {
            return user.updateStatus(payload);
        },
        async updateProfile({}, payload) {
            return user.updateProfile(payload);
        },
        async updatePassword({}, payload) {
            return user.updatePassword(payload);
        },
        async updateEmail({}, payload) {
            return user.updateEmail(payload);
        },
        async removeUser({commit, dispatch}, payload) {
            return user.remove(payload);
        },
        async destroyUser({commit, dispatch}, payload) {
            return user.destroy(payload);
        },
        async resetAdminPassword({commit, dispatch}, payload) {
            return user.resetAdminPassword(payload)
        }
    }

})
